@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: Multi-SemiBold;
  src: url('./fonts/Muli-SemiBold.woff');
}

@font-face {
  font-family: Multi-light;
  src: url('./fonts/Muli-Light.woff');
}


:root {
  --color-main: #940b0b;
  --color-darkMain: #740404;
  --color-tyc: #940b0b;
  --color-textDark: #ff5255;
  --color-tycText: #FFF;
  --color-amarillito: #F9CC07;
}

/*
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
*/

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  color: #FFF;
  background-color: #222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #5554;
}
*::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-darkMain);
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: 0%;
}

button.swal2-close {
  color: #FFF;
}

/* ---------------------------- */

.preloader {
  width: 40px;
  height: 40px;

  border: 5px solid transparent;
  border-top: 5px solid var(--color-main);
  border-left: 5px solid var(--color-main);
  border-bottom: 5px solid var(--color-main);
  border-radius: 50%;

  margin: 10px auto;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------- */
